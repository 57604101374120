<template>
    <div style="display: inline-block;padding-bottom: 5px;">
        <el-link :disabled="loading" @click="convert2canvas" class="share-btn" size="small" type="primary">
            <i :class="`${loading?'el-icon-loading':'el-icon-share'}`"></i>
            {{ loading ? '图片生成中...' : btnTitle }}
        </el-link>
        <!--    截图后的图片显示-->
        <el-dialog :title="`${dialogTitle}-预览`" :visible.sync="dialogImageVisible" @close="closefn" center width="80%">
            <div>
                <el-row>
                    <ul>
                        <li>
                            <a @click="downs" href="#" style="float: right"><span class="iconfont icon-qiandao"> 点击下载【{{
                        btnTitle
                        }}】到本地 </span></a>
                        </li>
                    </ul>
                </el-row>

                <el-image
                        :src="imgUrl"
                        alt="档期"
                        fit="fill"
                        style="width: auto; height: auto"></el-image>

                <el-row>
                    <el-col :span="24">
                        <el-button @click="downs" size="large" style="width: 100%;background-color:#67C23A "
                                   type="success">点击下载【{{
                            btnTitle
                            }}】到本地
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import html2canvas from 'html2canvas'

  export default {
    name: 'ImageShare',
    props: {
      //要分享的区域的ID
      shareContentId: {
        type: String,
        default() {
          return 'shareContent'
        }
      },
      btnTitle: {
        type: String,
        default() {
          return '分享'
        }
      },
      dialogTitle: {
        type: String,
        default() {
          return '图片分享'
        }
      }
    },

    data() {
      return {
        /**
         * 截图后的图片显示
         */
        dialogImageVisible: false,
        imgUrl: null,//图片链接
        loading: false,
        clickfn: false//true 显示 false隐藏btn
      }
    },
    methods: {
      convert2canvas() {
        this.clickfn = false
        this.loading = true
        this.$emit('dialogImageVisible', this.clickfn)
        setTimeout(() => {
          const shareContent = document.getElementById(this.shareContentId) // 需要绘制的部分的 (原生）dom 对象 ，注意容器的宽度不要使用百分比，使用固定宽度，避免缩放问题
          const width = shareContent.offsetWidth // 获取(原生）dom 宽度
          const height = shareContent.offsetHeight // 获取(原生）dom 高
          // const offsetTop = shareContent.offsetTop + 10 //元素距离顶部的偏移量
          const offsetTop = 20 //元素距离顶部的偏移量
          const canvas = document.createElement('canvas') //创建canvas 对象
          const context = canvas.getContext('2d')
          // var scaleBy = this.getPixelRatio(context); //获取像素密度的方法 (也可以采用自定义缩放比例)
          // console.log('scaleBy', scaleBy)
          const scaleBy = 1
          canvas.width = width * scaleBy //这里 由于绘制的dom 为固定宽度，居中，所以没有偏移
          canvas.height = (height + offsetTop) * scaleBy // 注意高度问题，由于顶部有个距离所以要加上顶部的距离，解决图像高度偏移问题
          context.scale(scaleBy, scaleBy)
          const opts = {
            allowTaint: true, //允许加载跨域的图片
            tainttest: true, //检测每张图片都已经加载完成
            scale: scaleBy, // 添加的scale 参数
            canvas: canvas, //自定义 canvas
            logging: true, //日志开关，发布的时候记得改成false
            width: width, //dom 原始宽度
            height: height //dom 原始高度
          }
          html2canvas(shareContent, opts).then((canvas) => {
            this.imgUrl = canvas.toDataURL('image/png')
            if (this.imgUrl !== '') {
              this.dialogImageVisible = true
              this.loading = false
            }
            // var body = document.getElementsByTagName("body");
            // body[0].appendChild(canvas);
          })
        }, 500)


      },
      closefn() {
        this.clickfn = true//显示
        this.$emit('dialogImageVisible', this.clickfn)
      },
      //获取像素密度
      getPixelRatio: function(context) {
        var backingStore =
          context.backingStorePixelRatio ||
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio ||
          1
        return (window.devicePixelRatio || 1) / backingStore
      },

      // vue 图片下载到本地，图片保存到本地: https://blog.csdn.net/weixin_30325793/article/details/97469827
      // downloadIamge(imgsrc, name) {//下载图片地址和图片名
      //   var image = new Image();
      //   // 解决跨域 Canvas 污染问题
      //   image.setAttribute("crossOrigin", "anonymous");
      //   image.onload = function() {
      //     var canvas = document.createElement("canvas");
      //     canvas.width = image.width;
      //     canvas.height = image.height;
      //     var context = canvas.getContext("2d");
      //     context.drawImage(image, 0, 0, image.width, image.height);
      //     var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      //
      //     var a = document.createElement("a"); // 生成一个a元素
      //     var event = new MouseEvent("click"); // 创建一个单击事件
      //     a.download = name || "photo"; // 设置图片名称
      //     a.href = url; // 将生成的URL设置为a.href属性
      //     a.dispatchEvent(event); // 触发a的单击事件
      //   };
      //   image.src = imgsrc;
      // },
      // downs(){
      //   this.downloadIamge(this.imgUrl, this.btnTitle)
      // },
      downs() {
        var alink = document.createElement('a')
        alink.href = this.imgUrl
        alink.download = this.btnTitle //图片名
        alink.click()
      }
    }
  }
</script>

<style scoped>
    .share-btn {
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 1px;
        font-size: 16px;
        font-weight: 600;
    }

    body {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppons", sans-serif;
        box-sizing: border-box;
        background-color: #333;
    }


    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        padding-bottom: 8px;
    }

    ul li {
        list-style: none;
        margin: 0 25px;
    }

    ul li a span {
        color: #333;
        letter-spacing: 2px;
        transition: 0.5s;
    }

    ul li a {
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 70px;
        background: #fff;
        position: relative;
        transition: 0.5s;
        font-size: 14px;
        border: 1px solid #e6f5de;
    }

    ul li a::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -16px;
        height: 100%;
        width: 16px;
        background-color: #cecece;
        transition: 0.5s;
        transform: skewY(-45deg);
    }

    ul li a::after {
        content: "";
        position: absolute;
        bottom: -16px;
        left: -8px;
        height: 16px;
        width: 100%;
        background-color: #cecece;
        transition: 0.5s;
        transform: skewX(-45deg);
    }

    ul li:hover {
        color: #fff;
    }

    ul li:hover span {
        color: #fff;
    }

    ul li:hover a {
        background-color: #67c23a;
        transform: translate(15px, -15px);
        box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
    }

    ul li:hover a::before {
        background-color: #377319;
    }

    ul li:hover a::after {
        background-color: #377319;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-right: 10px;
    }

    .icon-qiandao:before {
        content: "\edbc";
    }

    .icon-gonglve:before {
        content: "\edbd";
    }

    .icon-youxi:before {
        content: "\edc0";
    }

    .icon-huaban:before {
        content: "\edd2";
    }
</style>
